import React from 'react';
import axios from "axios";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Mettre à jour l'état pour que le prochain rendu montre l'UI de repli
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Vous pouvez aussi loguer l'erreur vers un service de reporting d'erreurs
        this.logErrorToServer(error, errorInfo);
    }

    logErrorToServer = (error, errorInfo) => {
        // Utilisez fetch ou axios pour envoyer l'erreur à votre serveur
        axios.post('/log-client-error', {
            error: error.toString(),
            errorInfo: errorInfo.componentStack
        })
            .then(response => {
                console.log('Erreur enregistrée avec succès');
            })
            .catch(error => {
                console.error('Erreur lors de l’enregistrement de l’erreur :', error);
            });
    }

    render() {
        if (this.state.hasError) {
            // Vous pouvez rendre n'importe quelle UI de repli
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
