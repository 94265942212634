import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);  // Assurez-vous que la valeur par défaut est null

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState({ token: null, userId: null, userName: null, userEmail: null });

    const login = (token, userId, userName, userEmail) => {
        setAuthToken({ token, userId, userName, userEmail });
    };

    const logout = () => {
        setAuthToken({ token: null, userId: null, userName: null, userEmail: null });
    };

    return (
        <AuthContext.Provider value={{ authToken, setAuthToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
