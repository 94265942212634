import { systemMessage } from "../systemMessage";
import axios from 'axios';
import { useAuth } from '../AuthContext';

const useSendMessage = (
  inputValue,
  setInputValue,
  setIsLoading,
  setSentImageUrls,
  setUploadedImageUrls,
  uploadedImageUrls,
  messages,
  setMessages
) => {
  const authInfo = useAuth();
  const userId = authInfo.authToken.userId;
  const userName = authInfo.authToken.userName;

  const handleSend = async (event, text) => {
    event?.preventDefault();
    setIsLoading(true);
   

    let messageContent = [];
    if (text.trim()) {
      messageContent.push({ type: "text", text: text.trim() });
    }

    uploadedImageUrls.forEach((url) => {
      messageContent.push({
        type: "image_url",
        image_url: { url },
      });
    });

    const newMessage = {
      role: "user",
      content: messageContent,
    };
    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setUploadedImageUrls([]);
    setInputValue('');
  

    // Enregistrer le message utilisateur dans la base de données
    try {
      await axios.post('/api/messages', {
        user: 'User',
        content: messageContent[0].text,
        role: 'user',
        userId: userId
      });
      console.log('Message logged successfully');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement des messages', error);
      // Log error, but continue
      try {
        await logClientError(userName, error, 'handleSend');
      } catch (logError) {
        console.error('Failed to log client error:', logError);
      }
    }

    // Enregistrer les images
    try {
      await axios.post('/api/images', {
        userId: userId,
        imagePath: messageContent[0].image_url.url
      });
      console.log('Image logged successfully');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement des images', error);
      // Log error, but continue
      try {
        await logClientError(userName, error, 'handleSend');
      } catch (logError) {
        console.error('Failed to log client error:', logError);
      }
    }

    // Appel à l'API OpenAI
    try {
      console.log('Sending request to OpenAI API');
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY_OAI}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: "gpt-4o",
          messages: [systemMessage, ...newMessages],
          max_tokens: 2500,
          temperature: 0.7,
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('OpenAI API Error:', errorData);
        // Log error, but continue
        try {
          await logClientError(userName, errorData, 'handleSend');
        } catch (logError) {
          console.error('Failed to log client error:', logError);
        }
      } else {
        const data = await response.json();
        const assistantMessage = { role: "assistant", content: data.choices[0].message.content };

        setMessages(prev => [...prev, assistantMessage]);

        try {
          await axios.post('/api/messages', {
            user: 'Assistant',
            content: assistantMessage.content,
            role: 'assistant',
            userId: userId
          });
          console.log('Assistant message logged successfully');
        } catch (error) {
          console.error('Erreur lors de l\'enregistrement des messages assistant', error);
          // Log error, but continue
          try {
            await logClientError(userName, error, 'handleSend');
          } catch (logError) {
            console.error('Failed to log client error:', logError);
          }
        }
      }
    } catch (error) {
      console.error('Erreur lors de la requête OpenAI', error);
      // Log error, but continue
      try {
        await logClientError(userName, error, 'handleSend');
      } catch (logError) {
        console.error('Failed to log client error:', logError);
      }
    } finally {
      setIsLoading(false);
      console.log('Finished processing handleSend');
    }

    setSentImageUrls(prev => [...prev, ...uploadedImageUrls]);
  };

  const logClientError = async (userName, error, location) => {
    try {
      await axios.post('/api/log-client-error', {
        error: `${userName} ${error.toString()}`,
        location: location
      });
      console.log('Client error logged successfully');
    } catch (logError) {
      console.error('Erreur lors de l\'enregistrement du log', logError);
    }
  };

  return { handleSend };
};

export default useSendMessage;
