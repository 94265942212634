import React, { useState } from 'react';
import axios from 'axios';
import API_URL from "./config/apiConfig";
import { useAuth } from './AuthContext'; // Importez useAuth

const AuthForm = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { setAuthToken } = useAuth(); // Utilisez useAuth pour accéder à setAuthToken

    const handleSubmit = async (e) => {
        e.preventDefault();
        //const endpoint = `${API_URL}/auth/login`;
       

        try {
            const response = await axios.post('/api/auth/login', { email, password });
            const { token, userName, userId, userEmail } = response.data;
            // console.log(response.data)
            setAuthToken({ token, userEmail, userId, userName }); // Mise à jour de l'état via le contexte
        } catch (err) {
            
        }
    };

    return (
        <div className="background flex flex-col justify-center">
            <img src="https://i.ibb.co/Fgmd13M/logo-Rond-min-3.png" className="w-96 h-auto m-auto" alt=""/>
            <div className="w-7/12 bg-[#265279] rounded-lg m-auto flex flex-col items-center justify-center space-y-6 h-auto py-4 ">
                <h2 className="text-2xl text-white font-black text-center pt-4 pb-6">{isLogin ? 'Connexion' : 'Inscription'}</h2>
                <form className="flex flex-row justify-center space-x-4" onSubmit={handleSubmit}>
                    <input
                        className="rounded-lg p-2 text-lg w-1/2"
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        className="rounded-lg p-2 text-lg w-1/2"
                        type="password"
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button className="bg-[#AC98FF] py-3 px-4 rounded-lg" type="submit">{isLogin ? 'Connexion' : 'Inscription'}</button>
                </form>
                <button onClick={() => setIsLogin(!isLogin)}>
                    {isLogin ? 'Première visite ? Cliquez ici pour vous inscrire' : 'Déjà inscrit ? connectez-vous'}
                </button>
            </div>
        </div>
    );
};

export default AuthForm;
